//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleSubtitle from './TitleSubtitle.vue'
import { lightenColor } from '~/utils'
export default {
  components: {
    TitleSubtitle,
  },
  props: {
    img: {
      type: [String, Object],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    altImg: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageError: false,
    }
  },
  computed: {
    imgSrc() {
      if (typeof this.img === 'string') {
        return this.img
      } else {
        const staticUrl = process.env.baseURLStatic
        const imgPath = this.img?.path || this.img || null
        if (imgPath) {
          return `${staticUrl}/resize/124x124/${imgPath}`
        }
      }
      return ''
    },
  },
  methods: {
    lightenColor,
    onImageError() {
      this.imageError = true
    },
  },
}
