//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppMenu from './components/AppMenu.vue'
import HeaderBar from './components/HeaderBar.vue'
import AppChangePartner from './components/AppChangePartner.vue'

import AppScrollToTopBtn from '~/components/AppScrollToTopBtn.vue'
import { makeNotificationSound } from '@/utils'

import AppConfirmModal from '@/plugins/confirmation/AppConfirmModal.vue'

const proccesNewOrderMsg = ({ orderId }, { toasted, $auth }) => {
  if (!$auth.user.scope.params?.notify_new_orders) return

  const msg = `Поступил новый заказ ${orderId}`
  toasted.info(msg, {
    position: 'top-right',
    duration: 4000,
  })
  makeNotificationSound()
}

const proccesSocketMsg = async (socketPayload, dependencies) => {
  const { payload, type } = socketPayload
  if (type === 'order_change') {
    const { systemOrdersRepository, $store, $route } = dependencies

    if (payload.state_ === 'new')
      proccesNewOrderMsg({ orderId: payload.order_id }, dependencies)

    if ($route.name !== 'orders') return
    const order = await systemOrdersRepository.show(payload.order_id)
    if (payload.state_ === 'new') {
      $store.commit(`orders/ADD_ITEM`, order.data)
    } else {
      $store.commit(`orders/UPDATE_ITEM`, order.data)
    }
  }
}
export default {
  components: {
    AppMenu,
    AppScrollToTopBtn,
    AppConfirmModal,
    AppChangePartner,
    HeaderBar,
  },
  provide() {
    return {
      toggleMenuProvide: this.toggleMenuWidth,
    }
  },
  data() {
    return {
      isMenuOpen: true,
      clipped: false,
      newOrderinterval: null,
      mobileMenu: [
        {
          icon: 'menu-home',
          to: { name: 'index' },
          text: 'Главная',
        },
        {
          icon: 'menu-orders',
          to: { name: 'gc-orders' },
          text: 'Заказы',
        },
        {
          icon: 'notification',
          to: { name: 'inspire' },
          text: 'Уведомления',
        },

        {
          icon: 'menu-lines',
          to: { name: 'AppMobileMenuPage' },
          text: 'Меню',
        },
      ],
      socket: null,
    }
  },
  watch: {
    isMenuOpen(vall) {
      localStorage.setItem('MENU_STATE', vall)
    },
  },
  methods: {
    toggleMenuWidth(val) {
      if (val) {
        this.isMenuOpen = !this.isMenuOpen
      } else {
        this.isMenuOpen = !this.isMenuOpen
      }
    },
  },
}
